import React, { useEffect, useState } from "react";
import "./pages.css";
import MainLayout from "./MainLayout";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, Container, Table, Col, Row } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import Swal from "sweetalert2";

const SociallyResponsive = () => {
  const [socially, setSocially] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(
      collection(db, "socially"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setSocially(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const deleteDatas = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDoc(doc(db, `socially/${id}`));
        Swal.fire("Deleted!", "Deleted Successfully.", "success");
      }
    });
  };
  const handleAddData = () => {
    navigate("/socially-form");
  };
  console.log(socially);
  return (
    <>
      <MainLayout />
      <Container style={{ width:"95%" }}>
        <Row>
          <Col style={{ padding: "30px", textAlign: "left" }} sm={6}>
            <h4>SOCIALLY RESPONSIVE</h4>
          </Col>
          <Col style={{ padding: "30px", textAlign: "right" }} sm={6}>
            <Button
              variant="dark"
              style={{ margin: "auto" }}
              onClick={() => handleAddData()}
            >
              <BsPlusLg />
            </Button>
          </Col>
          <hr />
        </Row>
      </Container>
      <div className="main-div">
        <Container style={{ width:"95%" }}>
          <Row>
            {socially &&
              socially.map((item) => (
                <Col sm={12} key={item.id}>
                  <Table  responsive="sm" responsive="md" responsive="lg">
                    <tbody>
                      <tr>
                        <td>
                          <div style={{ display: "flex", margin: "auto" }}>
                            <img
                              src={item.img}
                              style={{
                                height: "60px",
                                width: "60px",
                                margin: "auto",
                                borderRadius: "50%",
                              }}
                            />
                            <p style={{ margin: "auto" }}>{item.heading}</p>
                            <p style={{ margin: "auto", width: "350px" }}>
                              {item.paragragh}
                            </p>
                            <Button
                              variant="success"
                              style={{ margin: "auto" }}
                              onClick={() =>
                                navigate(`/socially-form/${item.id}`)
                              }
                            >
                              Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              variant="danger"
                              style={{ margin: "auto" }}
                              onClick={() => deleteDatas(item.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SociallyResponsive;

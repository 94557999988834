import React, { useEffect, useState } from "react";
import "./pages.css";
import MainLayout from "./MainLayout";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, Container, Table, Col, Row } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import Swal from "sweetalert2";

const Home = () => {
  const [home, setHome] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(
      collection(db, "home"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setHome(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDoc(doc(db, `home/${id}`));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  const handleAddData = () => {
    navigate("/home-form");
  };
  console.log(home);
  return (
    <>
      <MainLayout />
      <div className="main-div">
        <Container style={{ width: "95%" }}>
          <Row>
            <Col style={{ padding: "30px", textAlign: "left" }} sm={6}>
              <h4>ABOUT US</h4>
            </Col>
            <Col style={{ padding: "30px", textAlign: "right" }} sm={6}>
              <Button
                variant="dark"
                style={{ margin: "auto" }}
                // disabled
                onClick={() => handleAddData()}
              >
                <BsPlusLg />
              </Button>
            </Col>
            <hr />
          </Row>
        </Container>
        <Container style={{ width: "95%" }}>
          <Row>
            {home &&
              home.map((item) => (
                <Col sm={12} key={item.id}>
                            <h3
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.heading1}
                            </h3>
                            <br />
                            <h3 style={{ margin: "auto", textAlign: "left" }}>
                              {item.heading2}
                            </h3><br />
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.paragraph1}
                            </p><br />
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.paragraph2}
                            </p><br />
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.paragraph3}
                            </p><br />
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.paragraph4}
                            </p><br />
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "left",
                              }}
                            >
                              {item.paragraph5}
                            </p><br />
                            <Button
                              variant="success"
                              style={{ margin: "auto" }}
                              onClick={() => navigate(`/home-form/${item.id}`)}
                            >
                              Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              variant="danger"
                              style={{ margin: "auto" }}
                              onClick={() => handleDelete(item.id)}
                            >
                              Delete
                            </Button>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;

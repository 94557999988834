import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./pages.css";
import MainLayout from "./MainLayout";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, Container, Table, Row } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import Address from "./Address"

const Contact = () => {
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(
      collection(db, "contact"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setContact(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);
  
  const deleteDatas = async (id) => {
    if (window.confirm("Are you sure to delete that user?")) {
      try {
    await deleteDoc(doc(db, `contact/${id}`));
    alert("Data Successfully Deleted");
  } catch (err) {
    console.log(err);
  }
  };
}
  return (
    <>
      <MainLayout />
      <Container style={{ width: "90%", marginTop:"50px" }}>
      <Container>
                  {contact &&
                    contact.map((item) => (
                      <Row key={item.id}>
                        <Table responsive="sm">
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  style={{ display: "flex", margin: "auto" }}>
                                  <p style={{ margin: "auto" }}>
                                    {item.heading}
                                  </p>
                                  <p style={{ margin: "auto", width: "350px" }}>
                                    {item.paragragh}
                                  </p>
                                  <Button
                                    variant="success"
                                    style={{ margin: "auto" }}
                                    onClick={() =>
                                      navigate(`/contact-form/${item.id}`)
                                    }
                                  >
                                    Update
                                  </Button>&nbsp;&nbsp;
                                  <Button
                                  variant="danger"
                                   style={{ margin: "auto" }}
                                  onClick={() => deleteDatas(item.id)}
                                >
                                  Delete
                                </Button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    ))}
              </Container>
      <Address />
      </Container>
    </>
  );
};
export default Contact;

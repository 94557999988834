import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Grid, Loader, Form } from "react-bootstrap";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../firebase";
import "./pages.css";
import {
  addDoc,
  doc,
  collection,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const initialState = {
  heading1: "",
  heading2: "",
  paragraph1: "",
  paragraph2: "",
  paragraph3: "",
  paragraph4: "",
  paragraph5: "",
};

const HomeForm = () => {
  const [data, setData] = useState(initialState);
  const {
    heading1,
    heading2,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
  } = data;
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSingleUser();
  }, [id]);

  const getSingleUser = async () => {
    const docRef = doc(db, "home", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const validate = () => {
    let errors = {};
    if (!heading1) {
      errors.heading1 = "heading1 is Required";
    }
    if (!heading2) {
      errors.heading2 = "heading2 is Required";
    }
    if (!paragraph1) {
      errors.paragraph1 = "paragraph1 is Required";
    }
    if (!paragraph2) {
      errors.paragraph2 = "paragraph2 is Required";
    }
    if (!paragraph3) {
      errors.paragraph3 = "paragraph3 is Required";
    }
    if (!paragraph4) {
      errors.paragraph4 = "paragraph4 is Required";
    }
    if (!paragraph5) {
      errors.paragraph5 = "paragraph5 is Required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "home"), {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Thanks for contacting us. We will connect with you shortly...`,
        showConfirmButton: true,
        timer: 5000,
      });
      navigate("/home");
    } else {
      try {
        await updateDoc(doc(db, "home", id), {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
      Swal.fire({
        icon: "success",
        title: "Updated",
        text: `Thanks for contacting us. We will connect with you shortly...`,
        showConfirmButton: true,
        timer: 5000,
      });
      navigate("/home");
    }
  };

  return (
    <Container style={{ width: "90%" }} className="main-form">
      <div>
        <>
          <h4>{id ? "Update Home Data" : "Add Home Data"}</h4>
          <br />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="heading1"
                  error={errors.heading1 ? { content: errors.heading1 } : null}
                  placeholder="heading1"
                  onChange={handleChange}
                  value={heading1}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="heading2"
                  error={errors.heading2 ? { content: errors.heading2 } : null}
                  placeholder="heading2"
                  onChange={handleChange}
                  value={heading2}
                 
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="paragraph1"
                  error={
                    errors.paragraph1 ? { content: errors.paragraph1 } : null
                  }
                  placeholder="paragraph1"
                  onChange={handleChange}
                  value={paragraph1}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="paragraph2"
                  error={
                    errors.paragraph2 ? { content: errors.paragraph2 } : null
                  }
                  placeholder="paragraph2"
                  onChange={handleChange}
                  value={paragraph2}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="paragraph3"
                  error={
                    errors.paragraph3 ? { content: errors.paragraph3 } : null
                  }
                  placeholder="paragraph3"
                  onChange={handleChange}
                  value={paragraph3}
                   as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="paragraph4"
                  error={
                    errors.paragraph4 ? { content: errors.paragraph4 } : null
                  }
                  placeholder="paragraph4"
                  onChange={handleChange}
                  value={paragraph4}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="paragraph5"
                  error={
                    errors.paragraph5 ? { content: errors.paragraph5 } : null
                  }
                  placeholder="paragraph5"
                  onChange={handleChange}
                  value={paragraph5}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Row>
            <Button
              variant="success"
              type="submit"
              // disabled={progress !== null && progress < 100}
            >
              Submit
            </Button>
          </Form>
        </>
      </div>
    </Container>
  );
};

export default HomeForm;

import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import "./pages.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Mail = () => {
  const [mail, setMail] = useState("");
  
  const [menuData, setMenuData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [documentId, setDocumentId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "get_user_detail_by_mail"));
      setMenuData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))[0]
      );
    };
    fetchData();
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await updateDoc(doc(db, `get_user_detail_by_mail/${documentId}`), {
        mail: mail,
       
      });
      setIsEditing(false);
      setDocumentId("");
      alert("Data Successfully Updated");
    } else {
      await addDoc(collection(db, "get_user_detail_by_mail"), {
        mail: mail,
       
      });
      alert("Data Successfully Submitted");
    }
  };
  const deleteData = async (id) => {
    await deleteDoc(doc(db, `get_user_detail_by_mail/${id}`));
    alert("Data Successfully Deleted");
  };

  const editData = (data) => {
    setMail(data.mail);
    
    setIsEditing(true);
    setDocumentId(data.id);
  };

  return (
    <div className="main-div">
      <Container>
       <strong><h2>Contact_Us Details Mail Id </h2></strong><hr />
        <Row>
          <Col sm={6}>
           
            <Form onSubmit={submitForm}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email Id"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </Form.Group>
              <Button variant="success" type="submit">
                {isEditing ? "Update" : "Add Data"}
              </Button>
            </Form>
          </Col>
          <Col sm={6}>
            
            {menuData && Object.keys(menuData).length !== 0 && (
              <div>
                <p>{menuData.mail}</p>
               
                <br />
                <Button variant="secondary" onClick={() => editData(menuData)}>
                  Edit
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="danger"
                  onClick={() => deleteData(menuData.id)}
                >
                  Delete
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mail;
